import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import axios from 'axios';
import { ethers, utils } from 'ethers'
import Web3Modal from 'web3modal'
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import logoImg from '../../assets/images/alpha_icon.png';
import txtImg from '../../assets/images/sacred_shard.png';

import stakeIcon from '../../assets/images/stake.png';
import unstakeIcon from '../../assets/images/unstake.png';

import decrementIcon from '../../assets/icons/up.svg';
import incrementIcon from '../../assets/icons/down.svg';

import Modal from 'react-modal';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';




import { useSelector } from 'react-redux';
import './Mint.scss';
import {mintEnabled, setweb3provider} from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import Header from '../Header/Header'

import okWeb3 from '@okwallet/extension';

const { CHAINS } = okWeb3;


const alphaAbi = require('../../helper/alpha.json')
const stakeAbi = require('../../helper/staking.json')


const nftStakingAbi = require('../../helper/sacredshardstaking.json')
const sacredShardAbi = require('../../helper/sacredshard.json')


export default function Staking() {

  const [stakeModalIsOpen, setStakeModalIsOpen] = React.useState(false);
  const [unstakeModalIsOpen, setUnstakeModalIsOpen] = React.useState(false);


  
  const address = useSelector(state => state.address);
  const isOkx = useSelector(state => state.isOkx);
  const web3provider = useSelector(state => state.web3Provider);
  const [mintVisible, setMintVisible] = React.useState(true);
  const [amount, setAmount] = React.useState(0);
  const [supply, setSupply] = React.useState(0);

  const [alphaBalance, setAlphaBalance] = React.useState(0)
  const [rawAlphaBalance, setRawAlphaBalance] = React.useState(0)
  const [rawStakeBalance, setRawStakeBalance] = React.useState(0)
  const [stakeBalance, setStakeBalance] = React.useState(0)
  const [earnedBalance, setEarnedBalance] = React.useState(0)
  const [totalRewardBalance, setTotalRewardBalance] = React.useState(0)

  const [stakeAmount, setStakeAmount] = React.useState(0)
  const [unstakeAmount, setUnstakeAmount] = React.useState(0)
  const [showModal, setShowModal] = React.useState(false);

  const [stakeMax, setStakeMax] = React.useState(0)

  const [showUnstakeModal, setShowUnstakeModal] = React.useState(false);

  const [estimatedReward, setEstimatedReward] = React.useState(0);
  const [estimatedRewardDaily, setEstimatedRewardDaily] = React.useState(0);


  const [sacredShardNft, setSacredShardNft] = React.useState([])

  const [stakedSacredShardNft, setStakedSacredShardNft] = React.useState([])

  const [isValidated, setIsValidated] = React.useState(false)

  const contractAddress = process.env.GATSBY_STAKING_CONTRACT_ADDRESS
  const tokenAddress = process.env.GATSBY_ALPHA_CONTRACT_ADDRESS


  const nftAddress = process.env.GATSBY_SACREDSHARD_ADDRESS
  const nftStakingAddress = process.env.GATSBY_STAKING_NFT_CONTRACT_ADDRESS

  const handleClaimRewardsOkx = async () => {
    try{
        const iface = new utils.Interface(stakeAbi)
        const data = iface.encodeFunctionData("claimRewards", [1]);


        const payload = {
          from: address,
          to: contractAddress,
          data: data,
          value: '0x00',
        };

        const tx = await okWeb3.sendTransaction({chainName: CHAINS.ETHEREUM, payload})

        // Staking has been completed successfully, display the toast message
        toast.success('Reward Claimed Successfully');
      

        await loadEarnedOkx()
        await loadStakedOkx()
      }catch(err){
        console.log(err)
        toast.error('Claiming FAILED')
      }
  }



  useEffect(async() => {
    //Runs only on the first render
    console.log("called")
    if(web3provider){
      await loadSacredShard()
      await loadValidate()
      await loadEarned()
      await loadEarnedDaily()
      await loadStakedSacredShard()
    }else{
    }
  }, [web3provider]);

  const loadValidate = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftAddress, sacredShardAbi, signer);

      const approved = await contract.isApprovedForAll(address,nftStakingAddress);
      console.log("aproved",approved)
      setIsValidated(approved)
    }catch(err){

    }
  }

  const doValidate = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftAddress, sacredShardAbi, signer);

      const approveTx = await contract.setApprovalForAll(nftStakingAddress,nftAddress);

      await approveTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Approval Success');

      await loadValidate()
    }catch(err){

    }
  }

  const doStake = async(nft) => {
    try{
      console.log(nft)
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);
      const body = {
        address: address,
        amount: 1
      }
      let tier = nft.metadata.attributes.find(attribute => attribute.trait_type === "Tier")?.value;
      if(tier == "Legendary"){
        tier = 6
      }
      const resp = await axios.post('https://api.alphashards.com/api/signTier',body,{headers:{api_key:"R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m"}});

      const stakeTx = await contract.stakeNFT(nft.tokenId,0,tier,resp.data.nonce,resp.data.hash,resp.data.signature);

      await stakeTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Stake Nft Success'); 

      await loadStakedSacredShard()
      await loadSacredShard()
      await loadEarnedDaily()

    }catch(err){
      console.log(err)
    }
  }

  const doStakeAll = async() => {
    try{
      console.log(sacredShardNft)

      const tokenId = []
      const tiers = []
      sacredShardNft.map((nft) => {
        let tier = nft.metadata.attributes.find(attribute => attribute.trait_type === "Tier")?.value;
        if(tier == "Legendary"){
          tier = 6
        }
        tokenId.push(nft.tokenId)
        tiers.push(tier)
      })
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);
      const body = {
        address: address,
        amount: 1
      }

      console.log(tokenId)
      console.log(tiers)
      const url = process.env.GATSBY_STAKING_NFT_URL;
      
      const resp = await axios.post(url,body,{headers:{api_key:"R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m"}});

      const stakeTx = await contract.batchStakeNFT(tokenId,0,tiers,resp.data.nonce,resp.data.hash,resp.data.signature);

      await stakeTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Stake Nft Success'); 

      await loadStakedSacredShard()
      await loadSacredShard()
      await loadEarnedDaily()

    }catch(err){
      console.log(err)
    }
  }

  const doUnstakeAll = async() => {
    try{

      const tokenId = []
      stakedSacredShardNft.map((nft) => {
        tokenId.push(nft.tokenId)
      })
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const stakeTx = await contract.batchUnstakeNFT(tokenId,0);

      await stakeTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Unstake Nft Success');

      await loadSacredShard()
      await loadStakedSacredShard()

    }catch(err){
      console.log(err)
    }
  }

  const doUnstake = async(nft) => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const stakeTx = await contract.unstakeNFT(nft.tokenId,0);

      await stakeTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Unstake Nft Success');

      await loadSacredShard()
      await loadStakedSacredShard()

    }catch(err){
      console.log(err)
    }
  }

  const doClaim = async(nft) => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const claimTx = await contract.claimRewards(0);

      await claimTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Claim Reward Success');

      await loadEarned()

    }catch(err){
      console.log(err)
    }
  }

  const loadEarned = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const estimateReward = await contract.calculateEstimatedReward(0,address);

      const InEther = ethers.utils.formatEther(estimateReward);
      console.log(InEther)
      const formattedBalance = parseFloat(InEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

      console.log(formattedBalance)

      setEstimatedReward(formattedBalance)

    }catch(err){
      console.log(err)
    }
  }

  const loadEarnedDaily = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const estimateReward = await contract.calculateEstimatedRewardDaily(0,address);

      const InEther = ethers.utils.formatEther(estimateReward);
      console.log(InEther)
      const formattedBalance = parseFloat(InEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

      console.log(formattedBalance)

      setEstimatedRewardDaily(formattedBalance)

    }catch(err){
      console.log(err)
    }
  }

  const loadSacredShard = async() => {
    try{
    const query = `
      {
        sacredshardsNfts(first: 500, where: { owner: "${address}" }) {
              id
              owner
              tokenId
          }
      }
      `;
    const sacredShard = await axios.post('https://api.studio.thegraph.com/query/46966/sacredshards-nft-eth/version/latest', {query: query})

    let sacredshardNftData = sacredShard.data.data.sacredshardsNfts;

    setSacredShardNft(sacredshardNftData)


    const metadataPromises = sacredshardNftData.map(async (shard) => {
      const metadataResponse = await axios.get(`https://sacred-shard.alphashards.com/metadata/${shard.tokenId}`);
      shard.metadata = metadataResponse.data;
      return shard;
    });
  
    // Wait for all metadata requests to complete
    sacredshardNftData = await Promise.all(metadataPromises);
  
    console.log(sacredshardNftData);
    }catch(err){

    }
  }

  const loadStakedSacredShard = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const stakedNft = await contract.getStakerInfo(address, 0)

      let TokenIdArr = stakedNft[1];

      let stakedShard = []

      TokenIdArr = TokenIdArr.map((token) => {
        let obj = {}
        obj.tokenId = token.toNumber()
        stakedShard.push(obj)
      })
      console.log(TokenIdArr)

      const metadataPromises = stakedShard.map(async (shard) => {
        const metadataResponse = await axios.get(`https://sacred-shard.alphashards.com/metadata/${shard.tokenId}`);
        shard.metadata = metadataResponse.data;
        return shard;
      });
      const sacredshardNftData = await Promise.all(metadataPromises);
      console.log(sacredshardNftData)
      setStakedSacredShardNft(stakedShard)
    }catch(err){

    }
  }


  const loadEarnedOkx = async() => {
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');
    const contract = new ethers.Contract(contractAddress, stakeAbi, provider);
    const stakeBalance = await contract.earned(address,1)

    const totalEarned = await contract.getTotalRewardsClaimed(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setEarnedBalance(formattedBalance);
    setTotalRewardBalance(formattedTotalEarned)
  }

  const loadStaked = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.getStaked(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setRawStakeBalance(parseFloat(balanceInEther))
    setUnstakeAmount(balanceInEther)

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setStakeBalance(formattedBalance);
  }

  const loadStakedOkx = async() => {
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');
    const contract = new ethers.Contract(contractAddress, stakeAbi, provider);
    const stakeBalance = await contract.getStaked(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setRawStakeBalance(parseFloat(balanceInEther))
    setUnstakeAmount(parseFloat(balanceInEther) )

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setStakeBalance(formattedBalance);
  }


  return (
    <>
    {/* <header>
      <div className="logo-container">
      </div>
      <div className="connect-button-container">
        { !web3provider &&
          <button id="connect-button" onClick={connect}>CONNECT WALLET</button>
        }
      </div>
    </header> */}
    <motion.section 
    {...paragraphAnimation}
    id='Staking' className='Staking'>
      { mintVisible &&
      <div className='Mint__content'>
        <div className='flex-container-nft'>
          <div className='nftstake-title-container'>
                TOTAL ESTIMATED EARNING PER DAY: 
                <div className='wolf-icon-text'>
                  <img src={unstakeIcon}></img>
                  {estimatedRewardDaily}
                </div>

                
          </div>
          <div className='nftstake-title-container'>
                CLAIMABLE EARNING: 
                <div className='wolf-icon-text'>
                  <img src={unstakeIcon}></img>
                  {estimatedReward}
                </div>
                { !isValidated &&
                  <button className='validate-button' onClick={doValidate}>VALIDATE ALL</button>
                }
                { isValidated &&
                  <button className='validate-button' onClick={doStakeAll}>STAKE ALL</button>
                }
          </div>
          <div className='flex-nftstaking-left' style={{color:'white'}}>
                { sacredShardNft.map( (nft) => (

                  <div className='nftstake-item-card'>
                    <img src={nft.metadata?.image}></img>
                    <div className='nftstake-title'> SACRED SHARD #{nft.tokenId}   <a href={'https://opensea.io/assets/ethereum/0x4a25ce970dc8fa4f54eed6971a29c2812be0d8a7/'+nft.tokenId} target='_blank' style={{verticalAlign:'middle',marginLeft:'20px'}}><img src={opensea}></img></a> </div>
                    <button className='stake-button' onClick={()=>{doStake(nft)}}>STAKE</button>
                  </div>
                
                
                
                ))}

          </div>
          <div className='nftstake-title-container-right'>
              <button className='validate-button-bottom' onClick={doClaim}>CLAIM REWARD</button>
              <button className='validate-button-bottom' onClick={doUnstakeAll}>UNSTAKE ALL</button>
          </div>
          <div className='flex-nftstaking-right' style={{color:'white'}}>
                { stakedSacredShardNft.map( (nft) => (

                  <div className='nftstake-item-card'>
                    <img src={nft.metadata?.image}></img>
                    <div className='nftstake-title'> SACRED SHARD #{nft.tokenId}   <a href={'https://opensea.io/assets/ethereum/0x4a25ce970dc8fa4f54eed6971a29c2812be0d8a7/'+nft} target='_blank' style={{verticalAlign:'middle',marginLeft:'20px'}}><img src={opensea}></img></a> </div>

                    <button className='stake-button' onClick={()=> {doUnstake(nft)}}>UNSTAKE</button>
                  </div>

                ))}
            



          </div>
        </div>
        <footer className="footer">
          <div className="footer__section"> 
            <a href='https://etherscan.io/token/0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
            <a href='https://discord.com/invite/cGBG3Q9RdB'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
            <a href='https://twitter.com/ALPHAT0KEN'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
            <a href='https://opensea.io/collection/sacred-shard'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
            <a href='https://t.me/+T0KeRpvqgAU5Yjgx'> <img className="footer__icon" src={tg} alt="Icon 1"/></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x70235a346a1ec1d7a40181ff88a3a2e5260e1d04'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
            <a href='mailto:alpha.support@gmail.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
          </div>
          <div className="footer__section">
            $Alpha
          </div>
          <div className="footer__section">
            <p className="footer__copyright">© {new Date().getFullYear()} Copyrights & Protected</p>
          </div>
        </footer>
      </div>
      }
    </motion.section>
    </>
  );
}
